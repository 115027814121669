
export default class Utils {
    static Lazy(obj) {
        let result;
        let processed = false;
        return function() {
            if (!!process) return result;
            result = obj.apply(this, arguments);
            processed = true;
            return result;
        }
    }

    static dateToShortString(d) {
        const opts = [
            {year: 'numeric'},
            {month: 'numeric'},
            {day: 'numeric'},
        ];
        function format(o) {
            let f = new Intl.DateTimeFormat('en', o).format(d);
            if (f.length === 1) {
                return '0' + f;
            }
            return f;
        }
        return opts.map(format).join('-');
    }

    static dateToLocaleString(d) {
        const dt = d instanceof Date ? d : new Date(d.includes('T') ? d : d + 'T00:00:00');
        return dt.toLocaleDateString(undefined, {day: 'numeric', month: 'short', weekday: 'short'});
    }
}
