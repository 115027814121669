import React from 'react';

import Storage, { PREFS } from './Storage';
import Utils from './Utils';

const CARTS_VERSION = '1';

const CartContext = React.createContext();

export default function CartProvider({children}) {
    const [itemsCount, setItemsCount] = React.useState(0);

    const storage = new Storage();

    const getEmptyCarts = () => {
        const carts = {}
        carts[PREFS.CARTS_VERSION] = CARTS_VERSION;
        return carts;
    }

    const getCartsStorage = () => {
        const carts = JSON.parse(storage.getItem(PREFS.CARTS));
        if (!carts) {
            return getEmptyCarts();
        }
        if (carts[PREFS.CARTS_VERSION] !== CARTS_VERSION) {
            return getEmptyCarts();
        }
        return carts;
    }

    const getCarts = () => {
        const carts = getCartsStorage();
        const myCart = {}
        const today = Utils.dateToShortString(new Date());
        const expiredCarts = [];
        Object.keys(carts).forEach((cartKey) => {
            if (cartKey !== PREFS.CARTS_VERSION) {
                if (!carts[cartKey].cart) {
                    expiredCarts.push(carts[cartKey]);
                } else if (!carts[cartKey].items) {
                    expiredCarts.push(carts[cartKey]);
                } else if (carts[cartKey].cart.date < today) {
                    expiredCarts.push(carts[cartKey]);
                } else if (!carts[cartKey].items.length) {
                    expiredCarts.push(carts[cartKey]);
                } else {
                    myCart[cartKey] = carts[cartKey];
                    myCart[cartKey].cart.cart_id = cartKey;
                }
            }
        });
        if (!!expiredCarts.length) {
            saveCarts(myCart);
        }
        return myCart;
    }

    const getCartId = (cartInfo) => {
        if (('date' in cartInfo) && ('chef_id' in cartInfo)) {
            return cartInfo.date + '_' + cartInfo.chef_id;
        }
        return null;
    }

    const getEmptyCart = (cartInfo) => {
        return {
            cart: {...cartInfo, ...{suffix: new Date().getTime()}},
            items: [],
        }
    }

    const getCart = (cartInfo) => {
        const carts = getCartsStorage();
        const cartId = getCartId(cartInfo);
        const cartStatus = carts[cartId] && carts[cartId].cart.status && carts[cartId].cart.status.success;
        if (cartStatus) {
            return getEmptyCart(cartInfo);
        } else if (!!carts[cartId]) {
            return carts[cartId];
        }
        return getEmptyCart(cartInfo);
    }

    const saveCarts = (carts) => {
        const allCarts = {...carts};
        allCarts[PREFS.CARTS_VERSION] = CARTS_VERSION;
        storage.setItem(PREFS.CARTS, JSON.stringify(allCarts));
    }

    const saveCart = (cart) => {
        const carts = getCartsStorage();
        const cartId = getCartId(cart.cart);
        carts[cartId] = cart;
        saveCarts(carts);
    }

    const clearCartsStorage = () => {
        const carts = getEmptyCarts();
        saveCarts(carts);
        loadCarts();
    }

    const addCartItem = (cartInfo, newItem) => {
        const cart = getCart(cartInfo);
        const exists = cart.items.some((cartItem) => {
            if (cartItem.id === newItem.id) {
                cartItem.quantity += 1;
                return true;
            }
            return false;
        });

        if (!exists) {
            newItem.priceF = (newItem.price / 100).toFixed(2);
            newItem.quantity = 1;
            cart.items.push(newItem);
        }

        saveCart(cart);
    }

    const deleteCartItem = (cartInfo, item) => {
        const cart = getCart(cartInfo);
        const newItems = cart.items.filter((cartItem) => {
            return item.id !== cartItem.id;
        });
        cart.items = newItems;
        saveCart(cart);
    }

    const deleteSingleCart = (cartInfo) => {
        const carts = getCarts();
        const cartId = getCartId(cartInfo);
        if (cartId in carts) {
            delete carts[cartId];
            saveCarts(carts);
        }
    }

    const loadCarts = () => {
        const carts = getCartsStorage();
        let itemsCount = 0;
        Object.keys(carts).forEach((cartId) => {
            if (cartId !== PREFS.CARTS_VERSION) {
                itemsCount += carts[cartId].items && carts[cartId].items.length;
            }
        });
        setItemsCount(itemsCount);
    }

    const setCartStatus = (cartInfo, statusInfo) => {
        const cart = getCart(cartInfo);
        cart.cart.status = statusInfo;
        saveCart(cart);
    }

    const addItem = (cartInfo, item) => {
        addCartItem(cartInfo, item);
        loadCarts();
    }

    const deleteItem = (cartInfo, item) => {
        deleteCartItem(cartInfo, item);
        loadCarts();
    }

    const deleteCart = (cartInfo) => {
        deleteSingleCart(cartInfo);
        loadCarts();
    }

    const getTotal = (cartInfo) => {
        const carts = getCarts();
        const cartId = getCartId(cartInfo);
        let total = 0;
        Object.keys(carts).forEach((cartKey) => {
            const cart = carts[cartKey];
            if (cartId && (getCartId(cart.cart) !== cartId)) return;
            cart.items.forEach((item) => {
                total += item.price * item.quantity;
            });
        });
        total = total / 100;
        loadCarts();
        return total;
    }

    const values = {
        itemsCount: itemsCount,
    }

    const funcs = {
        addItem: addItem,
        getCarts: getCarts,
        deleteItem: deleteItem,
        deleteCart: deleteCart,
        getTotal: getTotal,
        setCartStatus: setCartStatus,
        clearCartsStorage: clearCartsStorage,
    }

    React.useEffect(() => {
        loadCarts();
    }, []);

    return (
        <CartContext.Provider value={{...values, ...funcs}}>
            {children}
        </CartContext.Provider>
    )
}

export const useCart = () => React.useContext(CartContext);
