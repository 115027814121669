export const HOST = 'https://dev.cookandtake.com';

export const PREFIX = '';
export const PREFIX_ACCOUNT = PREFIX + '/account';
export const PREFIX_CHEF = PREFIX + '/manage';

export const PREFIX_API = HOST + '/api/1';

export const CURRENCY = '$';

export const USER_ORDER_STATUS_COMPLETED = ['completed', 'invalid', 'user_cancelled'];

export const CHEF_ORDER_STATUS_COMPLETED = ['completed', 'delivered'];
export const CHEF_ORDER_STATUS_ACTIVE = ['acked', 'preparing', 'delivering', 'delayed'];
export const CHEF_ORDER_STATUS_CANCELLED = ['chef_cancelled', 'user_cancelled'];

export const ORDER_STATUS = {
    paid: 'Paid',
    refunded: 'Refunded',
    preparing: 'Preparing',
    completed: 'Completed',
    user_cancelled: 'Cancelled',
    chef_cancelled: 'Cancelled by chef',
    acked: 'Confirmed',
    delivering: 'Delivering',
    delivered: 'Delivered',
    delayed: 'Delayed',
};

export const ORDER_ACTIONS = {
    cancel: 'Cancel',
    delivering: 'Start delivering',
    delivered: 'Mark it as delivered',
    ack: 'Acknowledge',
};
