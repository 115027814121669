import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import AlertProvider from './Libs/AlertProvider';
import AuthProvider from './Libs/AuthProvider';
import CartProvider from './Libs/CartProvider';
import WebclientProvider from './Libs/Webclient';
import StripePayProvider from './Libs/StripePayProvider';

const AppRouter = React.lazy(() => import('./Public/AppRouter'));
// const stripePromise = loadStripe('pk_test_51KJmUhImEOjxNcnWNSaw6K29RPcgAw8odJI5yEydQi6JwIqQSfBP8v7gw6zn5ngyt499gjujXxrieQTESq4qj1qD006sDUUTOf');

function App() {
    return (
        <React.Suspense fallback={<></>}>
            <WebclientProvider>
                <AlertProvider>
                    <AuthProvider>
                    <StripePayProvider>
                    <CartProvider>
                        <React.Suspense fallback={<></>}>
                            <AppRouter/>
                        </React.Suspense>
                    </CartProvider>
                    </StripePayProvider>
                    </AuthProvider>
                </AlertProvider>
            </WebclientProvider>
        </React.Suspense>
    );
}

export default App;
