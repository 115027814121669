export const PREFS = {
    SESSION_ID: 'sid',
    USER: 'user',
    CARTS: 'carts',
    CARTS_VERSION: 'version',
};

export default class Storage {
    setItem(key, value) {
        localStorage.setItem(key, value);
    }

    getItem(key) {
        return localStorage.getItem(key);
    }

    deleteItem(key) {
        return localStorage.deleteItem(key);
    }

}
