import React from 'react';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const StripePayContext = React.createContext();

const stripePromise = loadStripe('pk_test_51KJmUhImEOjxNcnWNSaw6K29RPcgAw8odJI5yEydQi6JwIqQSfBP8v7gw6zn5ngyt499gjujXxrieQTESq4qj1qD006sDUUTOf');

function StripeMain({children}) {
    const stripe = useStripe();
    const elements = useElements();

    const confirmPayment = () => {
        stripe.confirmPayment({
            elements,
            confirmParams: {
                // return_url: '',
            },
        }).then((res) => {
            console.log('stripe confirm', res);
        });
    }

    const funcs = {
        confirmPayment: confirmPayment,
    }

    const values = {
    }

    return (
        <StripePayContext.Provider value={{...values, ...funcs}}>
            {children}
        </StripePayContext.Provider>
    )
}

export default function StripePayProvider({children}) {
    return (
        <Elements stripe={stripePromise}>
            <StripeMain>
                {children}
            </StripeMain>
        </Elements>
    )
}

export const useStripePay = () => React.useContext(StripePayContext);
